import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import _ from 'lodash'
import moment, { Moment } from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { updateReportFilterMetadata, updateReportFilterMetadataExact } from '../../redux/slices/report'
import FilterDateModal from './FilterDateModal'

moment().locale()

interface FilterDateInterface {
    title: string,
    valueSelected: string,
    setValueSelected: (valor: string) => void,
    setValue: (valor: dateIntervalInterface) => void,
    range: 'day' | 'week' | 'month' | 'year' | 'all',
    initialValue?: string,
    reloadOnRangeChange?: boolean,
    options: { name: string, value: string, range: ('day' | 'week' | 'month' | 'year' | 'all')[] }[]
    width?: number | string
    rangeInitialValues: {
        day?: string,
        week?: string,
        month?: string,
        year?: string,
        all?: string,

    },
    format?: string
    useReportId?: [string, number]
}

export interface dateIntervalInterface {
    lt?: string,
    gt?: string
}

let defaultInitialValue = {
    day: 'last_7_days',
    week: 'last_30_days',
    month: 'last_3_month',
    year: 'last_3_year',
    all: 'last_30_days',

}

let default_options = [
    { name: "Hoy", value: "today", range: ['day', 'all'] },
    { name: "Ayer", value: "yesterday", range: ['day', 'all'] },
    { name: "Últimos 7 días", value: "last_7_days", range: ['day', 'week', 'all'] },
    { name: "Últimos 14 días", value: "last_14_days", range: ['day', 'week', 'all'] },
    { name: "Últimos 30 días", value: "last_30_days", range: ['week', 'month', 'all'] },
    { name: 'Semana pasada', value: 'last_week', range: ['week', 'month', 'all'] },
    { name: "Esta semana", value: "this_week", range: ['week', 'month', 'all'] },
    { name: "Este mes", value: "this_month", range: ['week', 'month', 'all'] },
    { name: "Mes pasado", value: "past_month", range: ['week', 'month', 'all'] },
    { name: "Últimos 3 meses", value: "last_3_month", range: ['month', 'all'] },
    { name: "Últimos 6 meses", value: "last_6_month", range: ['month', 'all'] },
    { name: "Este año", value: "this_year", range: ['month', 'all'] },
    { name: "Año pasado", value: "past_year", range: ['month', 'year', 'all'] },
    { name: "Últimos 3 años", value: "last_3_year", range: ['year'] },
    { name: "Últimos 6 años", value: "last_6_year", range: ['year'] },
    { name: "Toda la historia", value: "all", range: ['year', 'all'] },
]


let momentToDate = (fecha_moment: Moment, removeOneDay?: boolean, format = '') => {
    if (format) {
        if (removeOneDay) fecha_moment.add(-1, 'd')
        return fecha_moment.format(format)
    } else {
        return fecha_moment.toISOString()
    }
}

export const handleChangeDate = (chg: string, format: string | undefined) => {
    const formatDate = (date, removeOneDay = false) => momentToDate(date, removeOneDay, format)

    switch (chg) {
        case 'today': return {
            gt: formatDate(moment().startOf('day'), true)
        }
        case 'yesterday': return {
            gt: formatDate(moment().subtract(1, 'day').startOf('day'), true),
            lt: formatDate(moment().startOf('day')),
        }
        case 'last_7_days': return {
            gt: formatDate(moment().subtract(7, 'day').startOf('day'), true),
        }
        case 'last_14_days': return {
            gt: formatDate(moment().subtract(14, 'day').startOf('day'), true),
        }
        case 'last_30_days': return {
            gt: formatDate(moment().subtract(30, 'day').startOf('day'), true),
        }
        case 'last_week': return {
            lt: formatDate(moment().startOf('week').startOf('day')),
            gt: formatDate(moment().subtract(1, 'week').startOf('week').startOf('day'), true),
        }
        case 'this_week': return {
            gt: formatDate(moment().startOf('week').startOf('day'), true),
        }
        case 'this_month': return {
            gt: formatDate(moment().startOf('month').startOf('day'), true),
        }
        case 'past_month': return {
            lt: formatDate(moment().subtract(1, 'month').endOf('month').endOf('day').add(-1, 'minute')),
            gt: formatDate(moment().subtract(1, 'month').startOf('month').startOf('day'), true),
        }
        case 'last_3_month': return {
            gt: formatDate(moment().subtract(3, 'month').startOf('month').startOf('day'), true),
        }
        case 'last_6_month': return {
            gt: formatDate(moment().subtract(6, 'month').startOf('month').startOf('day'), true),
        }
        case 'this_year': return {
            gt: formatDate(moment().startOf('year').startOf('day').subtract(1, 'minute'), true),
        }
        case 'last_3_year': return {
            gt: formatDate(moment().subtract(2, 'year').startOf('year').startOf('day'), true),
        }
        case 'last_6_year': return {
            gt: formatDate(moment().subtract(6, 'year').startOf('year').startOf('day'), true),
        }
        case 'past_year': return {
            lt: formatDate(moment().startOf('year').subtract(1, 'day').endOf('day')),
            gt: formatDate(moment().subtract(1, 'year').startOf('year').startOf('day'), true),
        }
        case 'all': return {}
        case 'custom': break;
        default: return {}
    }
}

const FilterDate = (params: FilterDateInterface) => {
    const { title, format, valueSelected, reloadOnRangeChange, setValue, setValueSelected, initialValue, options, range, rangeInitialValues, width, useReportId } = params
    let [localValueSelected, setLocalValueSelected] = useState(initialValue || valueSelected || 'all')
    const [open, setOpen] = useState(false);
    const [firstSetLocal, setFirstSetLocal] = useState(false);

    const dispatch = useDispatch()

    let valueSelectedTangible = useReportId ? useSelector((state: any) => _.get(state, `report.reports.${useReportId[0]}.filters_metadata[${useReportId[1]}]`, initialValue || valueSelected || 'all')) : localValueSelected

    let setValueSelectedTangible = useReportId ? (new_value) => dispatch(updateReportFilterMetadata({ id: useReportId[0], filter: useReportId[1], value: new_value })) : setLocalValueSelected
    let firstSet = useReportId ? useSelector((state: any) => _.get(state, `report.reports.${useReportId[0]}.statesFilter[${useReportId[1]}]`, false)) : firstSetLocal
    let setFirstSet = useReportId ? (new_value) => dispatch(updateReportFilterMetadataExact({ id: useReportId[0], filter: useReportId[1], value: new_value, url: 'firstSet' })) : setFirstSetLocal

    const handleOpen = () => setOpen(true);

    const handleSubmit = (startDate, endDate) => {
        if (format) {
            setValue({
                lt: endDate,
                gt: startDate,
            })
        } else {
            setValue({
                lt: new Date(endDate).toISOString(),
                gt: new Date(startDate).toISOString(),
            })
        }
    }

    useEffect(() => {
        let esta = options.filter((o) => o.range.includes(range) && o.value == valueSelectedTangible).length > 0
        if (valueSelectedTangible != 'custom' && (reloadOnRangeChange || !esta)) {
            if (rangeInitialValues[range]) {
                makeChange(rangeInitialValues[range])
            } else {
                makeChange(options.filter((o) => o.range.includes(range))[0]?.value)
            }
            setFirstSet(true)
        } else if (!firstSet) {
            makeChange(valueSelectedTangible)
            setFirstSet(true)
        }
    }, [range])

    let handleChange = (e: SelectChangeEvent<string>) => {
        makeChange(e.target.value + "")
    }

    const makeChange = (chg: string) => {
        if (chg === valueSelectedTangible) return;
        setValueSelectedTangible(chg)
        setValueSelected(chg)

        const newValue = handleChangeDate(chg, format)
        if (!newValue) return;

        setValue(newValue)
    }

    return (
        <div>
            <FilterDateModal
                setValue={handleSubmit}
                open={open}
                setOpen={setOpen}
                format={format}
            />
            <FormControl sx={{ width: width ?? "200px", mr: 2, my: 1, }} size="small">
                <InputLabel>{title}</InputLabel>
                <Select
                    value={valueSelectedTangible}
                    label={title}
                    size="small"
                    color="primary"
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                            },
                        },
                    }}
                >
                    {options.filter((o) => o.range.includes(range)).map((o) => (
                        <MenuItem key={o.value} sx={{ py: 0.1 }} value={o.value}>{o.name}</MenuItem>
                    ))}
                    <MenuItem sx={{ py: 0.1 }} value={'custom'} onClick={handleOpen}>Personalizado</MenuItem>
                </Select>
            </FormControl>

        </div>

    )
}

FilterDate.defaultProps = {
    setValueSelected: () => { },
    valueSelected: 'all',
    options: default_options,
    range: 'all',
    rangeInitialValues: defaultInitialValue
}

export default FilterDate